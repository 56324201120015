<template>
  <div class="df-flex-l df-flex-col main-diseases-picker">
    <h3>
      {{ $t('SowingView.main_diseases_picker.main_diseases') }}
      <span v-if="required">*</span>
    </h3>
    <v-skeleton-loader v-if="loading" height="32px" type="image" width="auto" />
    <v-chip-group
      v-else
      v-model="priorityDiseasesIndexes"
      column
      multiple
      :max="numberOfTargets"
      @change="changePriorityDisease"
    >
      <v-chip
        v-for="disease in cropDiseasesFiltered"
        :key="disease.id"
        class="disease__chip"
        label
        :active-class="diseaseChipColor(disease)"
        :disabled="disableTargetsSelectionByRules(disease.id)"
      >
        {{ $t(`diseases.${disease.code}`) }}
      </v-chip>
    </v-chip-group>
    <div class="df-flex-l">
      <div class="df-flex-sm align-center flex-grow-0">
        <div class="diseases__main"></div>
        <span>{{ $t('SowingView.main_diseases_picker.priority') }}</span>
      </div>
      <div class="df-flex-sm align-center flex-grow-0">
        <div class="diseases__secondary"></div>
        <span>{{ $t('SowingView.main_diseases_picker.secondary') }}</span>
      </div>
      <div
        v-if="showRequiredWarning"
        class="df-flex-sm align-center flex-grow-0"
      >
        <span class="diseases__warning">
          {{ $t('SowingView.main_diseases_picker.mandatory_field') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CropDiseasesMixin from '@/components/Spraying/CropDiseasesMixin.vue'
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'
import WhiteMoldMixin from '@/components/Spraying/WhiteMoldMixin.vue'
import { mapActions } from 'vuex'

export default {
  name: 'MainDiseasesPicker',

  mixins: [CropDiseasesMixin, WhiteMoldMixin],

  props: {
    editedSprayingId: {
      default: null,
      type: Number,
    },
    required: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
      mainDiseaseId: null,
      priorityDiseasesIndexes: [],
      secondaryDiseaseId: null,
      showRequiredWarning: false,
    }
  },

  inject: {
    getSprayingsLength: {
      from: 'getSprayingsLength',
    },
  },

  async created() {
    this.loading = true
    await this.fetchDiseases()
    if (this.editedSprayingId) await this.fetchCurrentDiseases()
    else await this.fetchDiseasesSuggestions()
    this.loading = false
  },

  watch: {
    mainDiseaseId(newValue) {
      this.$emit('diseases-chosen', newValue, null)
    },
    secondaryDiseaseId(newValue) {
      this.$emit('diseases-chosen', this.mainDiseaseId, newValue)
    },
  },

  computed: {
    numberOfTargets() {
      return process.env.VUE_APP_MAX_DOENCAS_PRIORIDADES
    },
  },

  methods: {
    ...mapActions('seasonsCrops', ['fetchDiseases']),
    changePriorityDisease(prioritizedDiseasesIndex) {
      if (!this.validate()) return
      const diseases = prioritizedDiseasesIndex.map(
        (index) => this.cropDiseasesFiltered[index]?.id
      )

      let mainDiseaseId = diseases[0]
      let secondaryDiseaseId = diseases[1]

      this.mainDiseaseId = mainDiseaseId
      this.secondaryDiseaseId = secondaryDiseaseId
    },
    diseaseChipColor(disease) {
      switch (disease.id) {
        case this.mainDiseaseId:
          return '--main-disease'
        case this.secondaryDiseaseId:
          return '--secondary-disease-first'
        default:
          return ''
      }
    },
    async fetchCurrentDiseases() {
      const params = {
        spraying_id: this.editedSprayingId,
      }
      try {
        const { data } = await sowingViewService.getCurrentDiseases(params)
        this.setPriorityDiseasesIndexes(data)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchDiseasesSuggestions() {
      const params = {
        spraying_number: this.getSprayingsLength() + 1,
      }
      try {
        const { data } = await sowingViewService.getDiseasesSuggestions(params)
        this.setPriorityDiseasesIndexes(data)
      } catch (error) {
        console.error(error)
      }
    },
    setPriorityDiseasesIndexes(data) {
      this.priorityDiseasesIndexes[0] = this.cropDiseasesFiltered.findIndex(
        (disease) => disease.id == data.main_disease_id
      )
      if (data.secondary_disease_id) {
        this.priorityDiseasesIndexes[1] = this.cropDiseasesFiltered.findIndex(
          (disease) => disease.id == data.secondary_disease_id
        )
      }
      this.changePriorityDisease(this.priorityDiseasesIndexes)
    },
    validate() {
      if (this.required && !this.priorityDiseasesIndexes.length) {
        this.showRequiredWarning = true
        return false
      } else {
        this.showRequiredWarning = false
        return true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main-diseases-picker {
  border: 1px solid #e6e9e6;
  border-radius: 8px;
  padding: 16px;

  h3 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    span {
      color: #f23528;
      font-size: 18px;
    }
  }
  span {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .diseases__main {
    height: 6px;
    width: 12px;
    border-radius: 8px;
    background-color: #f23528;
  }
  .diseases__secondary {
    height: 6px;
    width: 12px;
    border-radius: 8px;
    background-color: #f2cd12;
  }
  .diseases__warning {
    color: #f23528;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
  .disease__chip {
    border: 1px solid #e6e9e6;
    border-radius: 4px !important;
    padding: 10px 12px;
    margin: 0px;
    background-color: #fff !important;
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    &.--main-disease {
      background-color: #ffe1df !important;
      color: #f23528;
    }
    &.--secondary-disease-first {
      background-color: #fff2b0 !important;
      color: #b89900;
    }
    &.--disabled {
      background-color: #e6e9e6 !important;
      color: #aab2a9;
    }
  }
}
::v-deep .v-slide-group__content {
  padding: 0px !important;
  gap: 8px;
}
</style>
