<template>
  <df-modal
    persistent
    width="900px"
    :action-name="modalAction"
    :loading="loadingAPI"
    :title="modalTitle"
    @action-click="addEditSpraying"
    @close="closeAddSprayingModal"
  >
    <template #card-content>
      <v-form ref="form" class="df-flex-xl df-flex-col add-spraying-modal">
        <df-select
          v-model="selectedControl"
          disabled
          :items="controlTypes"
          :label="$t('SowingView.add_spraying_modal.control_type')"
          :placeholder="$t('SowingView.add_spraying_modal.diseases')"
        />
        <h2>
          {{ sprayingOrder }}º
          {{ $t('SowingView.add_spraying_modal.spraying') }}
        </h2>
        <main-diseases-picker
          ref="diseasePicker"
          required
          :edited-spraying-id="editedSpraying.id"
          @diseases-chosen="setDiseases"
        />
        <div v-for="(productTBA, index) in productsToBeAdded" :key="index">
          <div class="df-flex-none df-flex-xl-m df-flex-col-m modal__inputs">
            <div class="df-flex-xl">
              <div class="df-col-4 df-col-12-m">
                <df-select
                  v-model="productTBA.pesticide"
                  :items="formattedProducts"
                  :label="$t('SowingView.add_spraying_modal.pesticide')"
                  :loading="loadingProducts"
                  :placeholder="
                    $t('SowingView.add_spraying_modal.select_pesticide')
                  "
                  :rules="[required]"
                  @click="setProductInfos(productTBA.pesticide, index)"
                />
              </div>
              <div class="df-col-4 df-col-12-m">
                <df-input
                  v-model="productTBA.dose"
                  suffix="L/ha"
                  type="number"
                  :label="$t('SowingView.add_spraying_modal.dose')"
                  :placeholder="$t('SowingView.add_spraying_modal.insert_dose')"
                  :rules="[required]"
                />
                <div class="df-flex-xm align-center mt-4">
                  <p>
                    {{ $t('SowingView.add_spraying_modal.recommended_doses') }}
                  </p>
                  <dose-tooltip :product="productTBA" />
                </div>
              </div>
              <div class="df-col-4 df-col-12-m">
                <df-input
                  v-model="productTBA.price"
                  suffix="R$/L"
                  :label="$t('SowingView.add_spraying_modal.price')"
                  :placeholder="
                    $t('SowingView.add_spraying_modal.insert_price')
                  "
                  :rules="[required]"
                />
              </div>
            </div>
            <div
              v-if="showButtonPlaceholder(index)"
              class="button__placeholder"
            ></div>
            <df-button
              v-else
              class="button__delete"
              depressed
              height="44px"
              variant="text"
              min-width="44px"
              @click="removeProduct(index)"
            >
              <div class="df-flex-sm">
                <font-awesome-icon color="#F23528" icon="trash" />
                <span class="button__text">
                  {{ $t('SowingView.add_spraying_modal.delete') }}
                </span>
              </div>
            </df-button>
            <v-divider class="inputs__divider" />
          </div>
        </div>
        <div class="df-flex-l">
          <div class="df-col-12-m">
            <df-button
              class="new__product"
              color-icon="#39AF49"
              left-icon="plus"
              variant="secondary"
              @click="
                addNewProduct(events.sowingViewModule.SowingView.new_product)
              "
            >
              <p>{{ $t('SowingView.add_spraying_modal.new_pesticide') }}</p>
            </df-button>
          </div>
          <div class="df-col-12-m">
            <df-button
              v-if="!isUSA"
              class="product__options"
              color-icon="#39AF49"
              depressed
              variant="text-green"
              :icon="productOptionsIcon"
              @click="toggleProductOptions"
            >
              {{ $t('SowingView.add_spraying_modal.product__options') }}
            </df-button>
          </div>
        </div>
        <products-options
          ref="productOptions"
          v-if="isProducsOptionsOpened"
          :main-disease-id="mainDiseaseId"
          :secondary-disease-id="secondaryDiseaseId"
          @set-products="setProducts"
        />
      </v-form>
    </template>
  </df-modal>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfInput from '@/lib/components/Input/DfInput.vue'
import DfModal from '@/lib/Modal/DfModal.vue'
import DfSelect from '@/lib/components/Select/DfSelect.vue'
import DoseTooltip from '@/modules/sowingView/components/modal/DoseTooltip.vue'
import MainDiseasesPicker from '@/modules/sowingView/components/modal/MainDiseasesPicker.vue'
const ProductsOptions = () =>
  import('@/modules/sowingView/components/modal/ProductsOptions.vue')
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'
import { required } from '@/utils/formRules'
import { mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

const PRODUCT_OBJECT = {
  pesticide: {},
  dose: '',
  price: '',
  recommended_doses: {},
  unit: 'L',
}

export default {
  name: 'AddSprayingModal',

  components: {
    DfButton,
    DfInput,
    DfModal,
    DfSelect,
    DoseTooltip,
    MainDiseasesPicker,
    ProductsOptions,
  },

  props: {
    cropName: {
      required: true,
      type: String,
    },
    editedSpraying: {
      default: () => ({}),
      type: Object,
    },
    programId: {
      default: null,
      type: Number,
    },
    sowingId: {
      default: null,
      type: Number,
    },
  },

  data() {
    return {
      events,
      formattedProducts: [],
      loadingAPI: false,
      loadingProducts: false,
      mainDiseaseId: null,
      isProducsOptionsOpened: false,
      products: [],
      productsInfos: [],
      productsToBeAdded: [{ ...PRODUCT_OBJECT }],
      required,
      secondaryDiseaseId: null,
      selectedControl: {
        name: this.$t('SowingView.add_spraying_modal.diseases'),
        value: 'diseases',
      },
      sprayingOrder: null,
    }
  },

  inject: {
    getSprayingsLength: {
      from: 'getSprayingsLength',
    },
    requestPrograms: {
      default: () => {},
      from: 'requestPrograms',
    },
    requestSowingSprayings: {
      default: () => {},
      from: 'requestSowingSprayings',
    },
  },

  async created() {
    this.sprayingOrder = this.getSprayingsLength() + 1
    await this.requestProducts()
    await this.requestProductsInfos()
    if (Object.keys(this.editedSpraying).length) {
      this.fillInputs(this.editedSpraying.products)
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('seasonsCrops', ['currentSeasonCrop']),
    ...mapGetters('user', ['isUSA']),
    controlTypes() {
      return [
        {
          name: this.$t('SowingView.add_spraying_modal.diseases'),
          value: 'diseases',
        },
      ]
    },
    modalAction() {
      return this.editedSpraying.id
        ? this.$t('SowingView.add_spraying_modal.edit_spraying')
        : this.$t('SowingView.add_spraying_modal.add_spraying')
    },
    modalTitle() {
      return this.editedSpraying.id
        ? this.$t('SowingView.add_spraying_modal.edit_title')
        : this.$t('SowingView.add_spraying_modal.add_title')
    },
    productOptionsIcon() {
      return this.isProducsOptionsOpened ? 'chevron-up' : 'chevron-down'
    },
  },

  methods: {
    async addExecutedSpraying() {
      this.loadingAPI = true
      const addParams = {
        crop_name: this.cropName,
        main_disease_id: this.mainDiseaseId,
        products: this.productsToBeAdded,
        program_id: this.programId,
        secondary_disease_id: this.secondaryDiseaseId,
        sowing_id: this.sowingId,
        spraying_order: this.sprayingOrder,
      }
      try {
        await sowingViewService.createSpraying(addParams)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingView.add_spraying_modal.registered_spraying')
        )
        if (this.requestPrograms) this.requestPrograms()
        else this.requestSowingSprayings()
        this.closeAddSprayingModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingAPI = false
      }
    },
    addNewProduct(amplitudeEvent) {
      if (amplitudeEvent) logEvent(amplitudeEvent)
      this.productsToBeAdded.push({ ...PRODUCT_OBJECT })
    },
    async addPlanningSpraying() {
      this.loadingAPI = true
      const addParams = {
        crop_name: this.cropName,
        main_disease_id: this.mainDiseaseId,
        products: this.productsToBeAdded,
        program_id: this.programId,
        secondary_disease_id: this.secondaryDiseaseId,
        spraying_order: this.sprayingOrder,
      }
      try {
        await sowingViewService.createSpraying(addParams)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingView.add_spraying_modal.registered_spraying')
        )
        this.requestPrograms()
        this.closeAddSprayingModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingAPI = false
      }
    },
    async addSpraying() {
      logEvent(events.sowingViewModule.SowingView.save_spraying)
      if (this.sowingId) this.addExecutedSpraying()
      else this.addPlanningSpraying()
    },
    async addEditSpraying() {
      if (!this.$refs.form.validate() || !this.$refs.diseasePicker.validate())
        return
      if (this.editedSpraying.id) {
        this.editSpraying()
      } else {
        this.addSpraying()
      }
    },
    closeAddSprayingModal() {
      this.$emit('close')
    },
    async editSpraying() {
      this.loadingAPI = true
      const editParams = {
        crop_name: this.cropName,
        main_disease_id: this.mainDiseaseId,
        products: this.productsToBeAdded,
        secondary_disease_id: this.secondaryDiseaseId,
        spraying_id: this.editedSpraying.id,
      }
      try {
        await sowingViewService.editSpraying(editParams)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingView.saved_changes')
        )
        if (this.requestSowingSprayings) this.requestSowingSprayings()
        else this.requestPrograms()
        this.closeAddSprayingModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingAPI = false
      }
    },
    async requestProducts() {
      this.loadingProducts = true
      const params = {
        crop_id: this.currentSeasonCrop.crop_id,
        farm_id: this.currentFarmId,
      }
      try {
        const { data } = await sowingViewService.getProducts(params)
        this.products = data
        this.formattedProducts = data.reduce((acc, product) => {
          acc.push({
            name: product.name,
            value: String(product.id),
          })
          return acc
        }, [])
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingProducts = false
      }
    },
    async requestProductsInfos() {
      const params = {
        product_ids: this.products.map((product) => product.id),
      }
      try {
        const { data } = await sowingViewService.getProductsInfos(params)
        this.productsInfos = data
      } catch (error) {
        console.error(error)
      }
    },
    fillInputs(currentProducts) {
      const standardizedProducts = currentProducts.map((product) => {
        const currentProduct = this.products.find(
          (curProduct) =>
            curProduct.name.toLowerCase() === product.name.toLowerCase()
        )
        return {
          pesticide: {
            id: currentProduct.id,
            name: currentProduct.name,
            dose: product.dose_per_hectare,
            price: product.price_per_liter,
          },
        }
      })
      this.setProducts(standardizedProducts)
    },
    removeProduct(index) {
      this.productsToBeAdded.splice(index, 1)
    },
    setDiseases(mainDiseaseId, secondaryDiseaseId) {
      this.mainDiseaseId = mainDiseaseId
      this.secondaryDiseaseId = secondaryDiseaseId
      if (this.isProducsOptionsOpened)
        this.$nextTick(() => {
          this.$refs.productOptions.fetchProductSuggestions()
        })
    },
    setProducts(products) {
      if (products.length == 2 && this.productsToBeAdded.length < 2)
        this.addNewProduct()
      else if (this.productsToBeAdded.length > 2) {
        this.productsToBeAdded = [{ ...PRODUCT_OBJECT }, { ...PRODUCT_OBJECT }]
      }
      this.$nextTick(() => {
        products.forEach((product, index) => {
          this.setProductInfos(product.pesticide, index)
        })
      })
    },
    async setProductInfos(product, index) {
      const prodId = Number(product.id) || Number(product.value)
      const productInfo = this.productsInfos.find(
        (product) => product.pesticide_id == prodId
      )
      const updatedProduct = {
        ...this.productsToBeAdded[index],
        pesticide: {
          name: product.name,
          value: prodId,
        },
        dose: product.dose || productInfo.dose,
        price: product.price || productInfo.price,
        recommended_doses: this.products.find((product) => product.id == prodId)
          .doses,
        unit: productInfo.unit,
      }
      this.$set(this.productsToBeAdded, index, updatedProduct)
    },
    showButtonPlaceholder(index) {
      if (index === 0) return true
      return false
    },
    toggleProductOptions() {
      this.isProducsOptionsOpened = !this.isProducsOptionsOpened
      if (this.isProducsOptionsOpened) {
        logEvent(events.sowingViewModule.SowingView.product_options)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-spraying-modal {
  h2 {
    margin-top: -16px;
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .modal__inputs {
    align-items: center;
    flex-wrap: nowrap;
  }
  .inputs__divider {
    display: none;

    @include d(m) {
      display: block;
      width: 100%;
    }
  }
  .button__placeholder {
    width: 48px;
  }
  .button__delete {
    margin-bottom: 8px;
    width: 44px;

    @include d(m) {
      width: 100%;
      border: 1px solid #e6e9e6;
    }
    .button__text {
      display: none;

      @include d(m) {
        display: inline-block;
        color: #f23528;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
  .new__product {
    p {
      color: #1a2b46;
    }
    @include d(m) {
      width: 100%;
    }
  }
  .product__options {
    @include d(m) {
      width: 100%;
    }
  }
}
</style>
