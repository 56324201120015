<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <div v-on="on" data-id="tooltip-activator">
        <slot v-on="on">
          <font-awesome-icon icon="question-circle" />
        </slot>
      </div>
    </template>
    <div v-if="hasRecommendedDoses" class="df-flex-xm df-flex-col">
      <div
        v-for="([target_code, dose], index) in targetsAndDoses"
        :key="index"
        class="df-flex-xm"
      >
        <span class="disease__name">{{ $t(`diseases.${target_code}`) }} •</span>
        <span
          v-if="dose.recommended_dose == 999999.0"
          class="disease__quantity"
        >
          {{ $t('fungicida.sem_registro') }}
        </span>
        <span v-else class="disease__quantity">
          {{ $t('_to_', [dose.recommended_dose, dose.recommended_dose_max]) }}
          {{ product.unit }}
          /
          {{ $unitMeasures['area'][$currentLanguage()] }}</span
        >
      </div>
    </div>
    <span v-else class="empty__state">
      {{ $t('SowingView.dose_tooltip.choose_product') }}
    </span>
  </v-tooltip>
</template>

<script>
import CropDiseasesMixin from '@/components/Spraying/CropDiseasesMixin.vue'

export default {
  name: 'DoseTooltip',

  mixins: [CropDiseasesMixin],

  props: {
    product: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      targetsAndDoses: [],
    }
  },

  watch: {
    product: {
      handler() {
        this.targetsAndDoses =
          Object.entries(this.product.recommended_doses).filter(
            ([_, diseaseData]) => {
              return !this.ignoredDiseases.includes(diseaseData.target_id)
            }
          ) ?? []
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    hasRecommendedDoses() {
      return Object.keys(this.product.recommended_doses).length
    },
  },
}
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  background-color: #fff !important;
  padding: 12px;
  opacity: 1;
  box-shadow: 0px 4px 10px 0px rgba(20, 22, 27, 0.1);
}
.empty__state,
.disease__name {
  color: #5b6459;
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.disease__quantity {
  color: #5b6459;
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
::v-deep .svg-inline--fa {
  height: 15px;
  width: 15px;
}
</style>
